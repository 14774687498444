import React, {ReactNode} from 'react'
import { IconBase } from "react-icons";


import { AiOutlineCalendar, AiOutlineShoppingCart, AiOutlineAreaChart, AiOutlineBarChart, AiOutlineStock,AiOutlineLineChart  } from 'react-icons/ai';
// import { FiShoppingBag, FiEdit, FiPieChart, FiBarChart, FiCreditCard, FiStar, FiShoppingCart } from 'react-icons/fi';
import { BsGraphUp, BsGraphDown, BsCurrencyDollar, BsChatLeft, BsTrophy } from 'react-icons/bs';
import { FaEdit, FaPlus, FaRegSave, FaSun, FaMoon, FaStoreAlt, FaCheckCircle, FaTimesCircle,  FaSignOutAlt, FaBars, FaInfoCircle} from 'react-icons/fa';
import { TbRefresh, TbRefreshOff } from 'react-icons/tb';
import { RiCloseLine, RiMenu3Line} from 'react-icons/ri';
import { BiHelpCircle, BiRefresh } from 'react-icons/bi';
import { IoMdContacts } from 'react-icons/io';
import { PiChartLineDuotone } from "react-icons/pi";


export const PresetIcons: {[key: string]: ReactNode} = {
  'AiOutlineShoppingCart': <AiOutlineShoppingCart />, 
  'AiOutlineAreaChart': <AiOutlineAreaChart />, 
  'AiOutlineCalendar': <AiOutlineCalendar />, 
  'AiOutlineBarChart': <AiOutlineBarChart />, 
  'BsCurrencyDollar': <BsCurrencyDollar />,
  'AiOutlineStock': <AiOutlineStock />,
  'IoMdContacts': <IoMdContacts />,
  'BsChatLeft': <BsChatLeft />,
  'BsGraphUp':<BsGraphUp />,
  'BsGraphDown': <BsGraphDown />,
  'PiChartLineDuotone': <PiChartLineDuotone />,
  'BsTrophy': <BsTrophy />,
  'FaEdit': <FaEdit />,
  'FaPlus': <FaPlus />,
  'FaRegSave': <FaRegSave />,
  'FaSun': <FaSun />,
  'FaMoon': <FaMoon />,
  'FaStoreAlt': <FaStoreAlt />,
  'FaCheckCircle': <FaCheckCircle />,
  'FaTimesCircle' : <FaTimesCircle />,
  'BiHelpCircle': <BiHelpCircle />,
  'FaSignOutAlt': <FaSignOutAlt />,
  'Fabars': <FaBars />,
  'RiCloseLine': <RiCloseLine />,
  'RiMenu3Line': <RiMenu3Line />,
  'TbRefresh': <TbRefresh />,
  'TbRefreshOff': <TbRefreshOff />,
  'FaInfoCircle': <FaInfoCircle/>


}
// export const EditIcon = ({className}) => (
//   <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 576 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M402.6 83.2l90.2 90.2c3.8 3.8 3.8 10 0 13.8L274.4 405.6l-92.8 10.3c-12.4 1.4-22.9-9.1-21.5-21.5l10.3-92.8L388.8 83.2c3.8-3.8 10-3.8 13.8 0zm162-22.9l-48.8-48.8c-15.2-15.2-39.9-15.2-55.2 0l-35.4 35.4c-3.8 3.8-3.8 10 0 13.8l90.2 90.2c3.8 3.8 10 3.8 13.8 0l35.4-35.4c15.2-15.3 15.2-40 0-55.2zM384 346.2V448H64V128h229.8c3.2 0 6.2-1.3 8.5-3.5l40-40c7.6-7.6 2.2-20.5-8.5-20.5H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V306.2c0-10.7-12.9-16-20.5-8.5l-40 40c-2.2 2.3-3.5 5.3-3.5 8.5z"></path></svg>
// );



export const Colors = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#AF19FF'];

export const Label = props => {
  const { x, y, value } = props;
  return (
    <text
      x={x}
      y={y}
      dx={"2%"}
      dy={"-1%"}
      fontSize="15"
      fontWeight="bold"
      fill={"#181818"}
      textAnchor="left"
    >
      {value}
    </text>
  );
};

// interface LabelProps {
//   x:string,
//   y:number,
//   value:number,
// }