import React, { useState, useEffect, useRef } from "react";
import { useStateContext } from "../../context/ContextProvider.tsx";
import { Loading, Messagebox } from "../";
import ApiService from "../../services/apiService.tsx";
import { SelectedComponents } from "../../models/Types.tsx";
import { ComponentTypeIcons } from "../../util/iconMappting.ts";
import Component, { ComponentGrouping } from "../../models/Interfaces.tsx";

import "./componentlist.css";

export default function ComponentList({ title }: { title: string }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const stateContext = useStateContext();
  const hasFetched = useRef(false); // Prevent unnecessary re-fetching

  // Fetch data once on mount
  useEffect(() => {
    if (hasFetched.current) return;

    hasFetched.current = true;
    const fetchData = async () => {
      try {
        setIsLoading(true);

        const allComponents: Component[] = await ApiService.getAllComponents();
        const initialSelectedComponents = allComponents.map((component) => ({
          id: component.componentId,
          text: component.title,
          checkstate: stateContext.components.some((c) => c.componentId === component.componentId),
          componentGroup: component.componentGrouping,
          componentType: component.componentType,
        }));

        stateContext.updateSelectedComponents(initialSelectedComponents);
      } catch (error) {
        console.error("Error fetching components:", error);
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [stateContext]);

  // Group components by type
  const groupComponents = (components: SelectedComponents[]) => {
    return components.reduce((groups, component) => {
      const groupName = component.componentGroup === ComponentGrouping.Advanced ? 'Premium' : 'Standard'
      if (!groups[groupName]) groups[groupName] = [];
      groups[groupName].push(component);

      return groups;
    }, {} as Record<string, SelectedComponents[]>);
    //   const group = component.componentGroup || "Other";
    //   if (!groups[group]) groups[group] = [];
    //   groups[group].push(component);
    //   return groups;
    // }, {} as Record<string, SelectedComponents[]>);
  };

  // Toggle component selection
  const handleOnChange = (clickedComponent: SelectedComponents) => {
    stateContext.updateSelectedComponents(
      stateContext.selectedComponents.map((component) =>
        component.id === clickedComponent.id
          ? { ...component, checkstate: !component.checkstate }
          : component
      )
    );
  };

  const groupedComponents = groupComponents(stateContext.selectedComponents);

  return (
    <>
      {isLoading && <Loading />}
      {isError && (
        <Messagebox
          message="An error occurred. Please try again later."
          title="Error Loading Components"
        />
      )}
      {!isLoading && !isError && (
        <div className="componentlist-container">
          <h2 className="componentlist-title">{title}</h2>
          {Object.entries(groupedComponents).map(([group, components]) => (
            <fieldset key={group}>
              <legend>{group}</legend>
              <ul className="checkbox-container">
                {components.map((component) => (
                  <li key={component.id} className="list-item">
                    <label className="componentlist-label">
                      <input
                        type="checkbox"
                        checked={component.checkstate}
                        onChange={() => handleOnChange(component)}
                        disabled={!stateContext.user?.isActive}
                      />
                      <img
                        src={ComponentTypeIcons[component.componentType]}
                        alt={`${component.text} icon`}
                        className="component-icon"
                      />
                      {component.text}
                    </label>
                  </li>
                ))}
              </ul>
            </fieldset>
          ))}
        </div>
      )}
    </>
  );
}