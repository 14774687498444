import React, { useState, useEffect } from "react";
import { useStateContext } from "../../context/ContextProvider.tsx";
import ReactSpeedometer, { CustomSegmentLabelPosition, Transition } from "react-d3-speedometer";
// import { ResponsiveContainer } from 'recharts';

import Component from "../../models/Interfaces.tsx";
import { Errorcomponent } from "../";
import { ParseSimpleQueryResult, groupAndSumSimpleData } from "../../util/commonfunctions.tsx";

import "./speedometer.css";

export default function Speedometer(props: Component) {
  const { title, queryResult } = props;
  const pos = CustomSegmentLabelPosition.Inside;
  const transformx = Transition.easeElastic;
  const stateContext = useStateContext();
  const [textColor, setTextColor] = useState(
    stateContext.darkThemeEnabled ? '#FFFFFF' : '#555'
  );
  const [forceRender, setForceRender] = useState(false);
  const result = ParseSimpleQueryResult(queryResult);

  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setTextColor(stateContext.darkThemeEnabled ? '#FFFFFF' : '#555');
  }, [stateContext.darkThemeEnabled]);

  useEffect(() => {
    setMounted(true);
    return () => setMounted(false);
  }, []);

  if (result instanceof Error) {
    return (
      <Errorcomponent
        component={props}
        message="Error: kunne ikke parse resultatet"
      />
    );
  }

  const data = groupAndSumSimpleData(result);
  if (data.length !== 2) {
    return (
      <Errorcomponent
        component={props}
        message="OBS! Ikke tilstrekkelig data"
      />
    );
  }

  var percentageDifference =
    (data[0].Value === 0 || data[1].Value === 0)
      ? 0
      : ((data[1].Value - data[0].Value) / Math.abs(data[0].Value)) * 100;
      
  const percentageDifferenceFormatted = `${percentageDifference.toFixed(0)}%`;
  let speedometerValue = 500 + percentageDifference * 5;

  speedometerValue = Math.max(0, Math.min(speedometerValue, 1000));

  return (
    <div className="base-container">
      <div className="title-container speedometer-title-container">
        {title}
      </div>
      <div className="speedometer-wrapper" >
        <ReactSpeedometer
          fluidWidth={true}
          needleHeightRatio={0.7}
          value={speedometerValue}
          currentValueText={percentageDifferenceFormatted}
          labelFontSize="18"
          valueTextFontSize="22"
          customSegmentLabels={[
            { text: '-100%', position: pos, color: '#555' },
            { text: '-50%', position: pos, color: '#555' },
            { text: '0%', position: pos, color: '#555', fontSize: '19px' },
            { text: '50%', position: pos, color: '#555' },
            { text: '100%', position: pos, color: '#555' },
          ]}
          ringWidth={35}
          needleTransitionDuration={2000}
          needleTransition={transformx}
          needleColor={'#90f2ff'}
          textColor={textColor}
        />
      </div>
    </div>
  );
}
