// import { IconType } from 'react-icons';
import { CredentialsModel } from './Dtos';
import { OverlayProps, SelectedComponents } from './Types';
import { ReactNode } from 'react';

export enum ComponentType {
  Small = 0,
  Areachart = 1,
  Piechart = 2,
  Speedometer = 3,
  Simpleranking = 4,
  Linechart = 5,
  Barcharthorizontal = 6,
  Barchartvertical = 7,
  Complexranking = 8
}

enum DeviceType {
  PC = 0,
  Pad = 1,
  Mobile = 2
}
export enum ResultFormatting {
  Standard = 0,
  Currency = 1,
  Percentage = 2
}
export enum TotalFormatting
{
    None = 0,
    Sum = 1,
    Difference = 2, 
    Average = 3,
    SegmentedDifference = 4,
    
}
export enum ComponentGrouping {
  Standard = 0,
  Advanced = 1,
}

export default interface Component {
  componentId: number;
  userId: number;
  userComponentId: number;
  title: string;
  explanation: string;
  componentType: ComponentType;
  resultFormatting: ResultFormatting;
  totalFormatting:TotalFormatting;
  componentGrouping:ComponentGrouping;
  //query: string;
  queryResult: string;
  // extraIcon?: string;
  position: string;
  deviceType: DeviceType;
  filter: string[];
  connectionResults: ConnectionResults[];
}
export interface UserComponent {
  userId: number;
  componentId: number;
  position: string;
  devicetype: DeviceType;
  filter?: string;
}
export interface User {
  userId: number;
  username: string;
  isActive:boolean;
  budget: number;
  email?: string;
  supportContactInfo?: string;
  sMSText?: string;
  sMSPassword?: string;
  connectionsFilter: string;
  darkTheme: boolean;
  dateDayPreference: boolean;
  //todo logo
}
export interface ConnectionResults {
  licenseNo: string;
  name: string;
  data: string;
  isError: boolean;
  responseTime: string;
}
export interface SimpleModel {
  Variant: string;
  Value: number;
}
export interface ComplexModel {
  Serie: string;
  Xvalue: string;
  Yvalue: number;
}

export interface GroupedData {
  Xvalue: any;
  [Series: string]: number;
}

export interface ContextValue {
  credentials: CredentialsModel | null,
  setCredentials: React.Dispatch<React.SetStateAction<CredentialsModel | null>>;
  user: User;
  setUser: React.Dispatch<React.SetStateAction<User>>;
  components: Component[];
  setComponents: React.Dispatch<React.SetStateAction<Component[]>>;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  isError: boolean;
  setIsError: React.Dispatch<React.SetStateAction<boolean>>;
  errorMessage: string;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  darkThemeEnabled: boolean;
  setDarkThemeEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  timestamp: Date,
  setTimestamp: React.Dispatch<React.SetStateAction<Date>>;
  editMode: boolean;
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  dragging: boolean;
  setDragging: React.Dispatch<React.SetStateAction<boolean>>;
  filter: string[]
  setFilter: React.Dispatch<React.SetStateAction<string[]>>;
  connections: ConnectionResults[];
  setConnections: React.Dispatch<React.SetStateAction<ConnectionResults[]>>;
  fetchDataFromApi: () => Promise<void>;
  addComponents: (newComponents: Component[], position: string) => Promise<void>;
  removeComponent: (removeId: number) => Promise<void>;
  updatePositions: (updatedComponents: UserComponent[]) => Promise<void>;
  updateComponent: (componentid: number, position: string) => Promise<void>;
  updateComponents: (selectedComponents: SelectedComponents[]) => Promise<void>; //userComponents: SelectedComponents[]
  selectedComponents: SelectedComponents[];
  setSelectedComponents: React.Dispatch<React.SetStateAction<SelectedComponents[]>>;
  updateSelectedComponents: (selectedComponents: SelectedComponents[]) => void;
  updateUser: (user: User) => Promise<void>;
  getConnections: () => Promise<ConnectionResults[]>;
  //updateComponentFilters: (componentsToFilter: UserComponent[], filter: string[]) => Promise<void>;
  filterComponents: (filter: string[]) => void;
  showOverlay: boolean;
  setShowOverlay: React.Dispatch<React.SetStateAction<boolean>>;
  overlayContext?: OverlayProps | null;
  setOverlayContext: React.Dispatch<React.SetStateAction<OverlayProps | null>>;
  //getMySelectedComponents: () => SelectedComponents[];
}


export interface MessageboxProps {
  message: string;
  title: string;
  icon?: ReactNode | null;
}


export interface PayloadDTO {
  User: User,
  components?: Component[],
  connections?: ConnectionResults[]
}

export interface TableColumn {
  label: string;
  accessor: string;
  sortable: boolean;
  formatValue: ResultFormatting; // (value: any) => string
}
export interface TableProps {
  caption?: string;
  data: any;
  columns: TableColumn[];
  defaultSortField?: string;
  defaultSortOrder?: string;
  totalFormatting?: TotalFormatting;
}