import React from 'react'

import Component from '../../models/Interfaces.tsx';
import { getGradientByIndex, FormatResultValue, groupTwoColumnData, getLastKey, getFirstKey } from "../../util/commonfunctions.tsx";

import { Errorcomponent } from '../index.js'
import { ResponsiveContainer, PieChart, Pie, Cell, Legend, Tooltip } from 'recharts'

import "./piechartcomponent.css"

export default function Piechart(props: Component) {
  const { title, queryResult, resultFormatting } = props;

  let data: any[];
  try {
    const parsedData: any[] = JSON.parse(queryResult);
    data = groupTwoColumnData(parsedData).slice(0, 5);
  }
  catch (error) {
    return <Errorcomponent component={props} message="Error: Fant feil i dataene." />;
  }

  const CustomTooltip = ({ active, payload }) => {
    const compact = payload && payload.some(entry => entry.value > 100000);

    if (active && payload && payload.length) {
      const { name, value } = payload[0];
      const displayValue = FormatResultValue(value, resultFormatting, compact);
      return (
        <div className="custom-tooltip">
          <p>{`${name}:`}</p>
          <p>{`${displayValue}`}</p>
        </div>
      );
    }
    return null;
  };



  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    if (percent < 0.03)
      return null;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.7; 
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text x={x} y={y} fill="white" textAnchor="middle" dominantBaseline="middle" pointerEvents="none">
        {`${(percent * 100).toFixed(1)}%`}
      </text>
    );
  };


  const totalValue = () => {
    let sum = 0;
    let lastKey = "";
    if (data && data.length > 0) {
      lastKey = getLastKey(data[0]); 
      data.forEach(item => {
        sum += parseFloat(item[lastKey]); 
      });
    }
    return sum;
  };

  const lastKey = getLastKey(data[0]);
  const firstKey = getFirstKey(data[0])
  //const total = totalValue();

  const piechartData = data.map(item => ({
    name: item[firstKey],
    value: parseFloat(item[lastKey]),
    //percentage: (parseFloat(item[lastKey]) / total) * 100
  }));

  return (
    <div className="base-container ">
      <div className="title-container">
        {title}
      </div>
      <div className="piechart-container">
        <ResponsiveContainer width="100%" height="100%" >
          <PieChart>
            <defs>
              {data.map((entry, index) => (
                React.cloneElement(getGradientByIndex(index + 1, false), { key: `gradientHorizontal${index}` })
              ))}
            </defs>
            <Pie
              data={piechartData}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={80}
              fill="#FFFFFF"
              //label={({ name, percent }) => `${name} ${(percent * 100).toFixed(2)}%`}   
              labelLine={false}
              label={renderCustomizedLabel}
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={`url(#gradientHorizontal${index + 1})`} />
              ))}
            </Pie>

            <Tooltip cursor={{ fill: 'var(--text-secondary)' }} content={<CustomTooltip />} />
          </PieChart>
        </ResponsiveContainer>
        <Legend
          className='pie-chart-legend'
          layout="vertical"
          align="right"
          verticalAlign="middle"
          iconType="circle"
          // wrapperStyle={{  width: '40%', display: 'flex' }}
          payload={piechartData.map((entry, index) => ({
            value: entry.name,
            type: 'circle',
            color: `url(#gradientHorizontal${index + 1})`,
          }))}
        />
      </div>
    </div>
  )
}
