import React, { useEffect, useState } from 'react'
import { useStateContext } from '../../context/ContextProvider.tsx'
import { Loading, Messagebox, ToggleswitchControl } from '../index.js';

import "./connectionslist.css";
import { PresetIcons } from '../../util/constants.tsx';

export default function Connectionlist() {
  const stateContext = useStateContext();
  const { connections, setConnections } = stateContext;
  //const [connections, setConnections] = useState<ConnectionResults[]>([]);
  const [checkState, setCheckState] = useState<Record<string, boolean>>({});

  const [multiCheckerState, setMultiCheckerState] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  useEffect(() => {
    async function initializeCheckState() {
      // setIsLoading(true);
      try {
        const initialCheckState: Record<string, boolean> = {};
        if (stateContext.filter.length > 0) {
          connections.forEach(connection => {
            initialCheckState[connection.licenseNo] = stateContext.filter.includes(connection.licenseNo);
          });
        }
        else {
          connections.forEach(connection => {
            initialCheckState[connection.licenseNo] = true;
          });
        }
        setCheckState(initialCheckState);
        const allChecked = connections.every(con => initialCheckState[con.licenseNo] == true);
        setMultiCheckerState(allChecked);
      } catch (error) {
        console.error('Error in initializing check state:', error);
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    }

    initializeCheckState();
  }, []);


  const handleChangeAll = () => {
    const updatedCheckState: Record<string, boolean> = {};
    const updatedState = !multiCheckerState;

    Object.keys(checkState).forEach(licenseNo => {
      updatedCheckState[licenseNo] = updatedState;
    });

    setCheckState(updatedCheckState);
    setMultiCheckerState(updatedState);
  };

  const handleCheckChanged = (licenseNo: string) => {

    setCheckState(prevState => ({
      ...prevState,
      [licenseNo]: !prevState[licenseNo]
    }));


  };

  useEffect(() => {
    // Get an array of license numbers where the boolean value is true
    let newFilter = Object.entries(checkState)
      .filter(([licenseNo, isChecked]) => isChecked)
      .map(([licenseNo, _]) => licenseNo);

    // Set the filter to the new array

    const allUnchecked = Object.values(checkState).every(checked => !checked); //TODO can write this better;
    const allChecked = Object.values(checkState).every(checked => checked);
    if (allUnchecked) {
      setMultiCheckerState(false);
    }
    if (allChecked) {
      setMultiCheckerState(true);
      newFilter = [];
    }

    stateContext.setFilter(newFilter);
  }, [checkState]);
  return (
    <>
      {isLoading && <Loading />}
      {isLoading && isError && (
        <Messagebox
          message={stateContext.errorMessage.toString()}
          title="Ingen kontakt med APIet"
          icon={PresetIcons['FaTimesCircle']}
        />
      )}
      {!isLoading && !isError && (

        <div className="base-container">
          <div className="title-container">
            Mine Butikker
          </div>
          <div>
            <fieldset key={1}>
              <legend>Tilkoblinger</legend>
              <ul className="connections-container">
                <li key={-1} className="connections-list-item">
                  <div className="connections-label">
                    <ToggleswitchControl handleChange={handleChangeAll} isChecked={multiCheckerState} />
                    Velg alle/ingen
                  </div>
                </li>
                {connections.map((con, index) => (
                  <li key={index} className="connections-list-item">
                    <div className="connections-label">
                      <ToggleswitchControl handleChange={() => handleCheckChanged(con.licenseNo)} isChecked={checkState[con.licenseNo]} />
                      {con.licenseNo} - {con.name}

                    </div>
                    <div className="connections-list-item-status">
                      {con.isError ? (
                        <span style={{ color: 'var(--error-color)', fontSize:16 }}>Offline</span>
                      ) : (
                        <span style={{ color: 'var(--good-green-color)', fontSize:16 }}>Online</span>
                      )}
                    </div>
                  </li>
                ))}
              </ul>
            </fieldset>
          </div>
        </div>
      )}
    </>
  )

}

// useEffect(() => {
//   async function fetchConnections() {
//     setIsLoading(true);
//     try {
//       // const response = await stateContext.getConnections();
//       // setConnections(response);
//       // console.log(response, "setConnection Response");

//       const initialCheckState: Record<string, boolean> = {};
//       if(stateContext.filter.length > 0){
//         response.forEach(connection => {
//           initialCheckState[connection.licenseNo] = stateContext.filter.includes(connection.licenseNo);
//         });
//       }
//       else
//       {
//         response.forEach(connection => {
//           initialCheckState[connection.licenseNo] = true;
//         });
//       }
//       setCheckState(initialCheckState);
//       const allChecked = response.every(con => initialCheckState[con.licenseNo]);
//       setMultiCheckerState(allChecked);

//     } catch (error) {
//       console.error('Error in fetching connections:', error);
//       setIsError(true);
//       return <Messagebox
//         message={stateContext.errorMessage.toString()}
//         title="Ingen kontakt med APIet"
//         icon={PresetIcons['FaTimesCircle']}
//       />;
//     }
//     finally{
//       setIsLoading(false);
//     }
//   }
//   // console.log("running fetchConnectis")
//   //fetchConnections();
// }, []);
