import apiClient from '../api/apiClient.ts';
import { CredentialsModel } from "../models/Dtos";
import { User, UserComponent } from "../models/Interfaces";

const ApiService = {
  async getData(credentials: CredentialsModel) {
    try {
      const response = await apiClient.post('/Payloads/GetPayload', null, {
        headers: { credential: JSON.stringify(credentials) },
      });
      return response.data; // Returns response.data due to the interceptor
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  },

  async getAllComponents() {
    try {
      const response = await apiClient.get('/Components/GetAll');
      return response.data; 
    } catch (error) {
      console.error('Error fetching all components:', error);
      throw error;
    }
  },

  async updateUserComponent(component: UserComponent) {
    try {
      const response = await apiClient.put('/UserComponents/Update', component);
      return response.data; 
    } catch (error) {
      console.error('Error updating user component:', error);
      throw error;
    }
  },

  async updateUserComponents(components: UserComponent[], username: string) {
    try {
      const response = await apiClient.post('/UserComponents/UpdateAll', components, {
        headers: { username },
      });
      return response.data; 
    } catch (error) {
      console.error('Error updating user components:', error);
      throw error;
    }
  },

  async removeUserComponent(userComponentId: number) {
    try {
      const response = await apiClient.delete(`/UserComponents/Delete/${userComponentId}`);
      return response.data; 
    } catch (error) {
      console.error('Error removing user component:', error);
      throw error;
    }
  },

  async getAllConnections(credentials: CredentialsModel) {
    try {
      const response = await apiClient.post('/Payloads/GetConnections', null, {
        headers: { credential: JSON.stringify(credentials) },
      });
      return response.data; 
    } catch (error) {
      console.error('Error fetching connections:', error);
      throw error;
    }
  },

  async updateUserSettings(user: User) {
    try {
      const response = await apiClient.put('/User/UpdateSettings', user);
      return response.data; 
    } catch (error) {
      console.error('Error updating user settings:', error);
      throw error;
    }
  },

  async getFAQ() {
    try {
      const response = await apiClient.get('/FAQ/GetFaq');
      return response.data; 
    } catch (error) {
      console.error('Error fetching FAQ:', error);
      throw error;
    }
  },

  async createUser(credentials: CredentialsModel) {
    try {
      const response = await apiClient.post('/User/CreateUser', credentials);
      return response.data; 
    } catch (error) {
      console.error('Error creating user:', error);
      throw error;
    }
  },

  async authorizeUser(credentials: CredentialsModel) {
    try {
      const response = await apiClient.post('/User/AuthorizeUser', credentials);
      return response.data; 
    } catch (error) {
      console.error('Error authorizing user:', error);
      throw error;
    }
  },
};

export default ApiService;
