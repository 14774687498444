import axios from 'axios';

const API_URL = 'https://pckdashboardapi.azurewebsites.net/api';
//const API_URL = 'http://192.168.1.57:5143/api';
//const API_URL = 'http://localhost:5143/api';


const apiClient = axios.create({
  baseURL: API_URL, // Replace with your API URL 
  timeout: 80000, // Set timeout for requests
  headers: {
    'Content-Type': 'application/json',
  },
});

// Optional: Add interceptors for logging or error handling
apiClient.interceptors.request.use(
  (config) => {
    // console.log('Request:', config);
    return config;
  },
  (error) => {
    console.error('Request error:', error);
    return Promise.reject(error);
  }
);

apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      console.error('Response error:', error.response);
    } else if (error.request) {
      console.error('No response received:', error.request);
    } else {
      console.error('Request setup error:', error.message);
    }
    return Promise.reject(error);
  }
);

export default apiClient;