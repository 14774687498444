import React from 'react';
import { useStateContext } from '../../context/ContextProvider.tsx';
import './welcomepage.css';
import ApiService from '../../services/apiService.tsx';
import { CredentialsModel } from '../../models/Dtos.tsx';


const cred: CredentialsModel = { //TODO this entire consept needs to be moved outside I think.
  username: "lars.kristian.andersen@pck.no",
  password: "252S4M"
};

export default function Welcomepage(){

  const stateContext = useStateContext();

  const handleAccept = async() => {

    //TODO activate shit. 
    stateContext.setOverlayContext(null);
    
  }
  const handleCancel = () => {
    stateContext.setShowOverlay(false);
    stateContext.setOverlayContext(null);
  }
  
  return (
    <div className="welcome-page">
      <div className="welcome-page_title-container">Velkommen til PCKs nye Dashboard</div>
      <div className="welcome-page_body-container">
        <p>
        Vi i PCKasse har laget et nytt verktøy for å gi deg som gir god oversikt over hvordan din bedrift gjør det til enhver tid. 
        </p>
        <p>
        Vi tilbyr muligheten for å se hvilke varer som selger mest av, eller de du kanskje burde ha en kampanje på. Når kundestrømmen er sterkest og du trenger flere ansatte på jobb, og når du kanskje ikke trenger så mange. 
        </p>
        <p>
        Status på ordre, inntjening, dekningsgrad og mye mer for i år mot i fjor.
        </p>
        <p>
        Søyler, kake-diagrammer, grafer, speedometer mm. for å gi deg akkurat det du trenger ved bare et raskt overblikk. 
        </p>
        <p>
        Har du flere butikker kan du kjapt filtrere bort/legge til resultatet fra disse.
        </p>
        <p>
        Vi ønske å forenkle måten du får all den informasjonen du trenger slik at det blir kort vei til beslutninger og tiltak. 
        </p>
        <p>
        Ved å trykke aksepter vil du kunne prøve produktet helt gratis i 14 dager. Du trenger ikke kansellere hvis det ikke var noe for deg.
        </p>
        <p>
        Vi håper å se deg på den andre siden!
        </p>
      </div>
      <div className='welcome-page_action-buttons'>
        <button type='button' className='cancel-button' onClick={handleCancel}>
            Avbryt
        </button>
        <button type='button' className='accept-button' onClick={handleAccept}>
            Aksepter
        </button>
      </div>
    </div>
  );
}