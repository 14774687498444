import React, { useState, useEffect } from "react";
// import Logo from '../../assets/Logo.png'
import Logo from "../../assets/PCKDashboardLogo2.svg";
import "./header.css";
import { useStateContext } from "../../context/ContextProvider.tsx";

export default function Header() {
  const stateContext = useStateContext();
  const [lastFetched, setLastFetched] = useState(new Date());

  useEffect(() => {
    setLastFetched(new Date(stateContext.timestamp));
  }, [stateContext.timestamp]);

  return (
    <div className="header-container">
      <div className="header-logo">
        <img src={Logo} alt="Logo" />
      </div>
      <div className="header-timestamp">
        <p>Sist hentet: {lastFetched.toLocaleTimeString(undefined, { hour: "2-digit", minute: "2-digit" })}</p>
      </div>
    </div>
  );
}