import React, { useState, useEffect, ReactNode } from 'react'
import { useStateContext } from '../../context/ContextProvider.tsx';
import { Componentlist, ToggleswitchDarkmode, Connectionslist, Customtooltip, Faq } from '../../components'
import { PresetIcons } from '../../util/constants.tsx'
import { OverlayProps, SelectedComponents } from '../../models/Types.tsx';
import { RiCloseLine, RiMenu3Line } from 'react-icons/ri';

import "./actioncontainer.css";

export interface HamburgerProps {
  onClick: () => void;
  isOpen?: boolean;
}
export default function Actioncontainer() {
  const stateContext = useStateContext();
  const [mounted, setMounted] = useState(false);

  const [hoveredItemId, setHoveredItemId] = useState(null);
  const [tooltipText, setTooltipText] = useState('');
  const [toggleMenu, setToggleMenu] = useState(false);
  const [autoRefresh, setAutoRefresh] = useState(() => {
    const saved = localStorage.getItem('autoRefresh');
    return saved === 'true' ? true : false; // Ensures it falls back to false unless explicitly 'true'
  });

  useEffect(() => {
    setMounted(true); // Set mounted to true when the component mounts
    return () => {
      setMounted(false); // Set mounted to false when the component unmounts
    };
  }, []);

  function isEqualSet(setA, setB) {
    if (setA.size !== setB.size) {
      return false;
    }
    for (const item of setA) {
      if (!setB.has(item)) {
        return false;
      }
    }
    return true;
  }

  useEffect(() => {
    if (!autoRefresh)
      return;
    const interval = setInterval(() => {
      window.location.reload();
    }, 1800000); // Reload every 30 minutes; 1800000 millisec

    return () => clearInterval(interval);
  }, []);

  const toggleRefresh = () => {
    const newAutoRefreshState = !autoRefresh;
    setAutoRefresh(newAutoRefreshState);
    localStorage.setItem('autoRefresh', newAutoRefreshState.toString());
  }
  const updateSelectedComponentsOnCloseCallback = (selectedComponents: SelectedComponents[]) => {

    const selectedComponentIds = new Set(selectedComponents
      .filter(component => component.checkstate)
      .map(component => component.id));

    const existingComponentIds = new Set(stateContext.components.map(component => component.componentId));

    // Check if the selected component IDs are different from existing component IDs
    const shouldUpdate = !isEqualSet(selectedComponentIds, existingComponentIds);

    // Function to compare sets

    if (shouldUpdate) {
      stateContext.updateComponents(selectedComponents);
      stateContext.setTimestamp(new Date());
    }
  }
  const updateFilterOnCloseCallback = (filters: string[]) => {
    stateContext.filterComponents(filters);

    const filterString = filters.join(',');
    const updatedUser = { ...stateContext.user, connectionsFilter: filterString };
    stateContext.updateUser(updatedUser);
  }

  const toggleEditmode = () => {
    if (stateContext.isLoading || stateContext.isError)
      return;
    stateContext.setEditMode((prevEditMode) => !prevEditMode);
    setHoveredItemId(null);

  }

  const toggleAddComponents = () => {
    if (stateContext.isLoading || stateContext.isError)
      return;
    if (stateContext.editMode) {
      return;
      //toggleEditmode();
    }
    if (stateContext.user?.username) {
      const newOverlayContext: OverlayProps = {
        onClose: updateSelectedComponentsOnCloseCallback,
        children: <Componentlist title={"Liste med statistikker"} />
      };
      //children: <Componentgroups title={"Liste med statistikker"} components={componentListdata()} />
      stateContext.setOverlayContext(newOverlayContext);
      stateContext.setShowOverlay(true); //(prevShowOverlay) => !prevShowOverlay

    }
  }

  const toggleDarkMode = () => {
    // stateContext.setDarkThemeEnabled((prevDarkMode) => !prevDarkMode);
    stateContext.setDarkThemeEnabled((prevDarkMode) => {
      const newDarkMode = !prevDarkMode;
      localStorage.setItem('darkMode', JSON.stringify(newDarkMode));
      return newDarkMode;
    });
  };

  useEffect(() => {
    if (mounted) {
      const updatedUser = { ...stateContext.user, darkTheme: stateContext.darkThemeEnabled };
      stateContext.updateUser(updatedUser); //TODO Potentially a problem
    }

  }, [stateContext.darkThemeEnabled]);

  const toggleFilterStoreInOverlay = () => {
    if (stateContext.isLoading || stateContext.isError)
      return;
    if (!stateContext.showOverlay && stateContext?.connections?.length > 0) {
      const newOverlayContext: OverlayProps = {
        onClose: updateFilterOnCloseCallback,
        children: <Connectionslist />
      };
      stateContext.setOverlayContext(newOverlayContext);
    }
    else {
      stateContext.setOverlayContext(null);
    }
    stateContext.setShowOverlay((prevShowOverlay) => !prevShowOverlay);
  }

  const toggleFaq = () => {
    if (!stateContext.showOverlay) {
      const newOverlayContext: OverlayProps = {
        onClose: (() => { }),
        children: <Faq />
      };
      stateContext.setOverlayContext(newOverlayContext);
    }
    else {
      stateContext.setOverlayContext(null);
    }
    stateContext.setShowOverlay((prevShowOverlay) => !prevShowOverlay);
  }

  const handleComponentHover = (componentId, text) => {
    if (stateContext.editMode)
      return;
    if (hoveredItemId === componentId) {
      setHoveredItemId(null);
    }
    else {
      setTooltipText(text);
      setHoveredItemId(componentId);
    }
  };

  const [ratio, setRatio] = useState<ReactNode>(<></>);
  useEffect(() => {
    if (stateContext.components.length > 0) {
      const filterValue = stateContext.filter.length > 0 ? stateContext.filter.length : stateContext.connections.length
      const ratioValue = <>
        <span className="current">{filterValue} </span>
        <span className="separator"> / </span>
        <span className="total">{stateContext.connections.length}</span>
      </>;
      setRatio(ratioValue);
    }
    else {
      const ratioValue = <>
        <span className="current">{"- / -"} </span>
      </>
      setRatio(ratioValue);
    }

  }, [stateContext.filter]);

  const handleLogout = () => {
    // Remove the session token from sessionStorage
    sessionStorage.removeItem('token');
    stateContext.setCredentials(null);
    stateContext.setComponents([]);

    stateContext.setConnections([]);
    stateContext.setFilter([]);
    stateContext.setSelectedComponents([]);
    stateContext.setEditMode(false);
  };
  return (
    <>
      <div className="action-container">
        <div className="action-buttons">
          {stateContext.editMode ? (
            <button
              type="button"
              id="action-button-save"
              className="action-container-button"
              onClick={toggleEditmode}
              onMouseEnter={() => handleComponentHover('save', 'Lagre endringer')}
              onMouseLeave={() => handleComponentHover(false, '')}
              style={{ color: 'green' }}
            >
              {PresetIcons["FaRegSave"]}
            </button>
          ) : (
            <button
              type="button"
              id="action-button-edit"
              className="action-container-button"
              onClick={toggleEditmode}
              onMouseEnter={() => handleComponentHover('edit', 'Rediger komponentene.')}
              onMouseLeave={() => handleComponentHover(false, '')}
              style={{ color: 'inherit' }}
            >
              {PresetIcons["FaEdit"]}
            </button>
          )}
          <button
            type="button"
            className="action-container-button"
            onClick={toggleAddComponents}
            onMouseEnter={() => handleComponentHover('add', 'Legg til komponenter')}
            onMouseLeave={() => handleComponentHover(false, '')}
          >
            {PresetIcons["FaPlus"]}
          </button>
          <button
            type="button"
            className="action-container-button"
            onClick={toggleFilterStoreInOverlay}
            onMouseEnter={() => handleComponentHover('connections', 'Filterer resultat fra flere butikker.')}
            onMouseLeave={() => handleComponentHover(false, '')}
          >
            {PresetIcons["FaStoreAlt"]}
            <span className="notification-icon">{ratio}</span> {/* Example: display '3' as notification */}
          </button>
          <button
            type="button"
            className="action-container-button"
            onClick={toggleFaq}
            onMouseEnter={() => handleComponentHover('FAQ', 'Svar på ofte stilte spørsmål')}
            onMouseLeave={() => handleComponentHover(false, '')}
          >
            {PresetIcons["BiHelpCircle"]}
          </button>
          <button
            type="button"
            className="action-container-button"
            onClick={toggleRefresh}
            onMouseEnter={() => handleComponentHover('Refresh', 'Automatisk oppfriskning hvert 30 minutt')}
            onMouseLeave={() => handleComponentHover(false, '')}
          >
            {autoRefresh ? PresetIcons["TbRefresh"] : PresetIcons["TbRefreshOff"]}
          </button>
          <button
            type="button"
            className="action-container-button"
            onClick={handleLogout}
            onMouseEnter={() => handleComponentHover('Logout', 'Logger deg ut av dashboardet')}
            onMouseLeave={() => handleComponentHover(false, '')}
          >
            {PresetIcons["FaSignOutAlt"]}
          </button>
        </div>
        <div className="action-buttons_dark-mode">
          <ToggleswitchDarkmode isChecked={stateContext.darkThemeEnabled} handleChange={toggleDarkMode} />
        </div>
        <div className="action-buttons_burger-menu">
          {toggleMenu
            ? <RiCloseLine color="var(--text-primary)" size={27} onClick={() => setToggleMenu(false)} />
            : <RiMenu3Line color="var(--text-primary)" size={27} onClick={() => setToggleMenu(true)} />}
          {toggleMenu && (
            <div className="action-buttons_burger-menu_container">
              <div className="action-buttons_burger-menu_button_container">
                {stateContext.editMode ? (
                  <button
                    type="button"
                    id="action-button-save"
                    className="action-container-burger_button"
                    onClick={toggleEditmode}
                    // onMouseEnter={() => handleComponentHover('save', 'Lagre endringer')}
                    // onMouseLeave={() => handleComponentHover(false, '')}
                    style={{ color: 'var(--good-green-color)' }}
                  >
                    <p>Lagre</p>
                    {PresetIcons["FaRegSave"]}
                  </button>
                ) : (
                  <button
                    type="button"
                    id="action-button-edit"
                    className="action-container-burger_button"
                    onClick={toggleEditmode}
                    // onMouseEnter={() => handleComponentHover('edit', 'Rediger komponentene.')}
                    // onMouseLeave={() => handleComponentHover(false, '')}
                    style={{ color: 'inherit' }}
                  >
                    <p>Rediger</p>
                    {PresetIcons["FaEdit"]}
                  </button>
                )}
                <button
                  type="button"
                  className="action-container-burger_button"
                  onClick={toggleAddComponents}
                // onMouseEnter={() => handleComponentHover('add', 'Legg til komponenter')}
                // onMouseLeave={() => handleComponentHover(false, '')}
                >
                  <p>Legg Til</p>
                  {PresetIcons["FaPlus"]}
                </button>
                <button
                  type="button"
                  className="action-container-burger_button"
                  onClick={toggleFilterStoreInOverlay}
                // onMouseEnter={() => handleComponentHover('connections', 'Filterer resultat fra flere butikker.')}
                // onMouseLeave={() => handleComponentHover(false, '')}
                >
                  <p>Filtrer</p>
                  {PresetIcons["FaStoreAlt"]}

                </button>
                <button
                  type="button"
                  className="action-container-burger_button"
                  onClick={toggleFaq}
                // onMouseEnter={() => handleComponentHover('FAQ', 'Svar på ofte stilte spørsmål')}
                // onMouseLeave={() => handleComponentHover(false, '')}
                >
                  <p>FAQ</p>
                  {PresetIcons["BiHelpCircle"]}
                </button>
                <button
                  type="button"
                  className="action-container-burger_button"
                  onClick={toggleRefresh}
                // onMouseEnter={() => handleComponentHover('Refresh', 'Automatisk oppfriskning hvert 30 minutt')}
                // onMouseLeave={() => handleComponentHover(false, '')}
                >
                  <p>Oppfrisk</p>
                  {autoRefresh ? PresetIcons["TbRefresh"] : PresetIcons["TbRefreshOff"]}
                </button>
                <button
                  type="button"
                  className="action-container-burger_button"
                  onClick={handleLogout}
                // onMouseEnter={() => handleComponentHover('Logout', 'Logger deg ut av dashboardet')}
                // onMouseLeave={() => handleComponentHover(false, '')}
                >
                  <p>Logg ut</p>
                  {PresetIcons["FaSignOutAlt"]}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      {hoveredItemId && <Customtooltip text={tooltipText} />}
    </>
  );
}


// const bodyElement = document.body;
// const rootStyles = getComputedStyle(document.documentElement);
// const backgroundColor = stateContext.darkThemeEnabled ? rootStyles.getPropertyValue('--background-color-dark') : rootStyles.getPropertyValue('--background-color-light');
// const fontColor = stateContext.darkThemeEnabled ? rootStyles.getPropertyValue('--font-color-dark') : rootStyles.getPropertyValue('--font-color-light');
// bodyElement.style.setProperty('background-color', backgroundColor);
// bodyElement.style.setProperty('color', fontColor);
// stateContext.updateUser();