import React, { createContext, useState, useEffect, useContext } from 'react';
import ApiService from '../services/apiService.tsx';
import Component, { ContextValue, User, UserComponent, ConnectionResults } from '../models/Interfaces';
import { SelectedComponents, OverlayProps } from '../models/Types';
import { CredentialsModel } from '../models/Dtos';

const StateContext = createContext<ContextValue | null>(null);

type Props = {
  children: React.ReactNode;
};

export const ContextProvider = ({ children }: Props) => {
  const [credentials, setCredentials] = useState<CredentialsModel | null>(null);
  const [user, setUser] = useState<User>({} as User);
  const [components, setComponents] = useState<Component[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [timestamp, setTimestamp] = useState(new Date());
  const [editMode, setEditMode] = useState(false);
  const [darkThemeEnabled, setDarkThemeEnabled] = useState<boolean>(() => {
    const storedDarkMode = localStorage.getItem('darkMode');
    return storedDarkMode ? JSON.parse(storedDarkMode) : false;
  });
  const [connections, setConnections] = useState<ConnectionResults[]>([]);
  const [filter, setFilter] = useState<string[]>([]);
  const [showOverlay, setShowOverlay] = useState(false);
  const [overlayContext, setOverlayContext] = useState<OverlayProps | null>(null);
  const [dragging, setDragging] = useState(false);
  const [selectedComponents, setSelectedComponents] = useState<SelectedComponents[]>([]);

  /** Fetch user data and components from the API */
  const fetchDataFromApi = async () => {
    setIsLoading(true);
    try {
      if (!credentials) throw new Error('No credentials provided');

      const data = await ApiService.getData(credentials);

      const { user: fetchedUser, components: fetchedComponents, connections: fetchedConnections } = data;
      setUser(fetchedUser);

      setFilter(fetchedUser.connectionsFilter ? fetchedUser.connectionsFilter.split(',') : []);
      setComponents(fetchedComponents);
      setDarkThemeEnabled(fetchedUser.darkTheme ?? false);
      setConnections(fetchedConnections);
      setTimestamp(new Date());
    } catch (error) {
      console.error('Error fetching data from API:', error);
      setErrorMessage(error instanceof Error ? error.message : 'An unknown error occurred');
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  /** Add new components */
  const addComponents = async (newComponents: Component[], position: string): Promise<void> => {
    setIsLoading(true);
    try {
      const myFilter = filter.join(', ');
      const userComponents = newComponents.map((component) => ({
        userId: user.userId,
        componentId: component.componentId,
        position,
        devicetype: 0,
        filter: myFilter,
      }));
      const updatedComponents = await ApiService.updateUserComponents(userComponents, user.username);
      setComponents((prev) => [...prev, ...updatedComponents]);
    } catch (error) {
      console.error('Error adding components:', error);
      setErrorMessage(error instanceof Error ? error.message : 'An unknown error occurred');
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  /** Remove a specific component */
  const removeComponent = async (removeId: number): Promise<void> => {
    try {
      await ApiService.removeUserComponent(removeId);
      setComponents((prev) => prev.filter((component) => component.userComponentId !== removeId));
    } catch (error) {
      console.error('Error removing component:', error);
    }
  };

  /** Update user settings */
  const updateUser = async (updatedUser: User): Promise<void> => {
    try {
      if (!updatedUser.username) throw new Error('No user provided');
      await ApiService.updateUserSettings(updatedUser);
    } catch (error) {
      console.error('Error updating user:', error);
    }
  };

  /** Get user connections */
  const getConnections = async (): Promise<ConnectionResults[]> => {
    if (!credentials) throw new Error('No credentials provided');
    return await ApiService.getAllConnections(credentials);
  };

  /** Update selected components */
  const updateSelectedComponents = (selcomp: SelectedComponents[]): void => {
    if (!selcomp || selcomp.length < 1) return;
    setSelectedComponents(selcomp);
  };

  /** Update a single component */
  const updateComponent = async (componentId: number, position: string): Promise<void> => {
    const updatedUserComponent: UserComponent = {
      userId: user.userId,
      componentId: componentId,
      position: position,
      devicetype: 0,
    };
    try {
      await ApiService.updateUserComponent(updatedUserComponent);
    } catch (error) {
      console.error('Error updating component:', error);
    }
  };

  /** Update multiple components */
  const updateComponents = async (newSelectedComponents: SelectedComponents[]): Promise<void> => {
    setIsLoading(true);
    try {
      const myFilter = filter.join(', ');

      // Map the new components to include current positions or default to "0"
      const userComponents = newSelectedComponents
        .filter((component) => component.checkstate) // Only include selected components
        .map((component) => {
          const existingComponent = components.find((c) => c.componentId === component.id);
          return {
            userId: user.userId,
            componentId: component.id,
            position: existingComponent ? existingComponent.position : '0', // Retain current position or assign "0"
            devicetype: 0,
            filter: myFilter,
          };
        });

      // Send the updated components to the API
      const refreshedComponents = await ApiService.updateUserComponents(userComponents, user.username);

      // Update state with refreshed components
      setComponents(refreshedComponents);
    } catch (error) {
      console.error('Error updating components:', error);
      setErrorMessage(error instanceof Error ? error.message : 'An unknown error occurred');
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  /** Update positions */
  const updatePositions = async (updatedComponents: UserComponent[]): Promise<void> => {
    try {
      await ApiService.updateUserComponents(updatedComponents, user.username);
    } catch (error) {
      console.error('Error updating positions:', error);
    }
  };

  /** Filter components */
  const filterComponents = (licenseNoFilter: string[]): void => {
    try {
      const updatedComponents = components.map((component) => {
        const filteredResults = licenseNoFilter.length === 0
          ? component.connectionResults.filter((result) => !result.isError).map((result) => result.data)
          : component.connectionResults
            .filter((result) => !result.isError && licenseNoFilter.includes(result.licenseNo))
            .map((result) => result.data);

        const mergedData = JSON.stringify(
          filteredResults.flatMap((result) => JSON.parse(result))
        );

        return { ...component, queryResult: mergedData };
      });

      setComponents(updatedComponents);
    } catch (error) {
      console.error('Error filtering components:', error);
    }
  };

  useEffect(() => {
    if (credentials) fetchDataFromApi();
  }, [credentials]);

  const contextValues: ContextValue = {
    credentials,
    setCredentials,
    user,
    setUser,
    components,
    setComponents,
    isLoading,
    setIsLoading,
    isError,
    setIsError,
    errorMessage,
    setErrorMessage,
    darkThemeEnabled,
    setDarkThemeEnabled,
    timestamp,
    setTimestamp,
    editMode,
    setEditMode,
    showOverlay,
    setShowOverlay,
    overlayContext,
    setOverlayContext,
    dragging,
    setDragging,
    fetchDataFromApi,
    addComponents,
    removeComponent,
    updateUser,
    connections,
    getConnections,
    setConnections,
    selectedComponents,
    setSelectedComponents,
    updateSelectedComponents,
    updateComponent,
    updateComponents,
    updatePositions,
    filterComponents,
    filter,
    setFilter,
  };

  return (
    <StateContext.Provider value={contextValues}>
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = (): ContextValue => {
  const context = useContext(StateContext);
  if (!context) throw new Error('useStateContext must be used within a ContextProvider');
  return context;
};
