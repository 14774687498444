
import { ComponentType } from '../models/Interfaces.tsx';
import GraphIcon from "../assets/componentlist/graph-24.png";
import MoneyIcon from "../assets/componentlist/money-48.png";
import PiechartIcon from "../assets/componentlist/piechart-48.png";
import SpeedometerIcon from "../assets/componentlist/speedometer-32.png";
import RankingIcon from "../assets/componentlist/rank-48.png";
import BarchartIcon from "../assets/componentlist/ranking-40.png";


export const ComponentTypeIcons = {
  [ComponentType.Small]: MoneyIcon,
  [ComponentType.Areachart]: GraphIcon,
  [ComponentType.Piechart]: PiechartIcon,
  [ComponentType.Speedometer]: SpeedometerIcon,
  [ComponentType.Simpleranking]: RankingIcon,
  [ComponentType.Linechart]: GraphIcon,
  [ComponentType.Barcharthorizontal]: BarchartIcon,
  [ComponentType.Barchartvertical]: BarchartIcon,
  [ComponentType.Complexranking]: RankingIcon,
};

