import React, { useState, useEffect } from 'react';
import {AccordionItem, Loading, Messagebox} from './../index.js';
import { PresetIcons } from '../../util/constants.tsx';
import ApiService from '../../services/apiService.tsx';
import { FAQDTO } from '../../models/Dtos.tsx';

import "./faq.css";

export default function FAQ(){
  const [activeItem, setActiveItem] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [faqData, setFaqData] = useState<FAQDTO[] | null>([]);

  async function getFAQdata() {
    setIsLoading(true);
    try {
      const data = await ApiService.getFAQ();
      setFaqData(data);
    } 
    catch (error)
    {
      setErrorMessage("Prøv igjen senere.");
      setIsError(true);
    }
    finally{
      setIsLoading(false);
    }
  }
  useEffect(() => {
    getFAQdata();

  }, []);


  const handleItemClick = (index: number) => {
    setActiveItem(activeItem === index ? null : index);
  };

  return (
    <>
    <div className='title-container faq-title'>
      Ofte stilt spørsmål
    </div>
  
    {isLoading && <Loading />}
    {!isLoading && isError && (
      <Messagebox
        message={errorMessage}
        title="Ingen kontakt med APIet"
        icon={PresetIcons['FaTimesCircle']}
      />
    )}
    {!isLoading && !isError && (
      <>
        <span className="faq-subtitle">Trykk for å utvide</span>
        <div className="faq">
          {!faqData || faqData.length === 0 ? (
            <span>Ingen FAQ funnet</span>
          ) : (
            faqData.map((item, index) => (
              <AccordionItem
                key={index}
                question={item.question}
                answer={item.answer}
                isActive={activeItem === index}
                onClick={() => handleItemClick(index)}
              />
            ))
          )}
        </div>
      </>
    )}
  </>
    
  );
}