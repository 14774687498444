import React from 'react'
import { SimpleModel, ComplexModel, GroupedData, TableColumn, ResultFormatting, ConnectionResults, TotalFormatting } from './../models/Interfaces.tsx';

// export function ParseSimpleQueryResult(queryResult: string): SimpleModel[] | Error {
//   try {
//     const parsedQueryResult = JSON.parse(queryResult) as object[];
//     const comparerModels: SimpleModel[] = parsedQueryResult.map(item => ({
//       Variant: Object.values(item)[0] as string,
//       Value: Object.values(item)[1] as number
//     }));
//     return comparerModels;
//   } catch (error) {
//     console.error('Error parsing QueryResult:', error);
//     return new Error('Could not load or parse component data');
//   }
// }

export function ParseSimpleQueryResult(queryResult: string): SimpleModel[] | Error {
  try {
    const parsedQueryResult = JSON.parse(queryResult) as object[];
    const comparerModels: SimpleModel[] = parsedQueryResult.map(item => {
      // Dynamically determine the keys based on the structure
      const keys = Object.keys(item);
      if (keys.length !== 2) {
        throw new Error("Unexpected number of keys in query result item.");
      }

      // Assign keys explicitly to maintain order
      const [variantKey, valueKey] = keys;

      return {
        Variant: item[variantKey] as string,
        Value: item[valueKey] as number,
      };
    });

    return comparerModels;
  } catch (error) {
    console.error('Error parsing QueryResult:', error);
    return new Error('Could not load or parse component data');
  }
}

export function ParseComplexQueryResult(queryResult: string): ComplexModel[] | Error {
  try {
    const parsedQueryResult = JSON.parse(queryResult) as object[];
    const complexModels: ComplexModel[] = parsedQueryResult.map(item => ({
      Serie: Object.values(item)[0] as string,
      Xvalue: Object.values(item)[1] as string,
      Yvalue: Object.values(item)[2] as number
    }));
    return complexModels;
  }
  catch (error) {
    console.error('Error parsing QueryResult:', error);
    return new Error('Could not load or parse component data');
  }
}

export function transformComplexData(inputData: ComplexModel[]): GroupedData[] {
  const groupedData: { [key: string]: { [key: string]: number } } = inputData.reduce(
    (acc, current) => {
      const { Serie, Xvalue, Yvalue } = current;

      // Ensure Xvalue is treated as a string
      const stringValue = Xvalue.toString();
      if (!acc[stringValue]) {
        acc[stringValue] = {};
      }

      acc[stringValue][Serie] = Yvalue;


      return acc;
    }, {}
  );

  const transformedData: GroupedData[] = Object.entries(groupedData).map(
    ([Xvalue, seriesData]) => {
      const outputEntry: GroupedData = { Xvalue: parseInt(Xvalue, 10) };

      Object.entries(seriesData).forEach(([Series, value]) => {
        outputEntry[Series] = value;
      });

      return outputEntry;
    }
  );

  return transformedData;
}
// export function groupAndSumSimpleData(inputData: SimpleModel[]): SimpleModel[] {
//   const groupedData: { [key: string]: number } = {};

//   inputData.forEach(({ Variant, Value }) => {
//     if (!groupedData[Variant]) {
//       groupedData[Variant] = 0;
//     }
//     groupedData[Variant] += Value;
//   });

//   const transformedData: SimpleModel[] = Object.entries(groupedData).map(
//     ([Variant, sum]) => ({ Variant, Value: parseFloat(sum.toFixed(2)) })
//   );
//   transformedData.sort((a, b) => b.Value - a.Value);

//   return transformedData;
// }
export function groupAndSumSimpleData(inputData: SimpleModel[]): SimpleModel[] {
  const groupedData: { [key: string]: number } = {};

  // Group and sum the values
  inputData.forEach(({ Variant, Value }) => {
    if (!groupedData[Variant]) {
      groupedData[Variant] = 0;
    }
    groupedData[Variant] += Value;
  });

  // Transform the grouped data back to the original structure
  const transformedData: SimpleModel[] = inputData.map(({ Variant }) => ({
    Variant,
    Value: parseFloat(groupedData[Variant]?.toFixed(2) || "0"),
  }));

  // Filter duplicates to retain the first occurrence in the input order
  const uniqueData = transformedData.filter(
    (item, index, self) =>
      index === self.findIndex((other) => other.Variant === item.Variant)
  );

  return uniqueData;
}

export function transformDataToGroupedData(data: any[]): GroupedData[] {
  if (data.length == 0) return [];

  const numColumns = Object.keys(data[0]).length;
  let transformedData: GroupedData[] = [];

  if (numColumns === 2) {
    // Handling case with 2 columns
    const keys = Object.keys(data[0]);
    const dataMap = new Map<any, number>();

    // Group and sum the Yvalues by Xvalue
    data.forEach(item => {
      const xValue = item[keys[0]];
      const value = Number(item[keys[1]]);

      if (dataMap.has(xValue)) {
        dataMap.set(xValue, dataMap.get(xValue)! + value);
      } else {
        dataMap.set(xValue, value);
      }
    });

    transformedData = Array.from(dataMap.entries()).map(([xValue, value]) => ({
      Xvalue: xValue,
      [keys[1]]: value
    }));

  } else if (numColumns === 3) {
    // Handling case with 3 columns
    const keys = Object.keys(data[0]);
    const allXValues = new Set<any>();

    // Collect all unique X-values
    data.forEach(item => {
      allXValues.add(item[keys[1]]);
    });

    const sortedXValues = Array.from(allXValues).sort((a, b) => a - b);

    // Create a map to hold the grouped data
    const dataMap = new Map<any, GroupedData>();

    data.forEach(item => {
      const seriesKey = item[keys[0]];
      const xValue = item[keys[1]];
      const value = Number(item[keys[2]]);

      if (!dataMap.has(xValue)) {
        dataMap.set(xValue, { Xvalue: xValue });
      }

      const mapEntry = dataMap.get(xValue)!;
      mapEntry[seriesKey] = (mapEntry[seriesKey] || 0) + value;
    });

    // Ensure all series have entries for each X-value
    transformedData = sortedXValues.map(xValue => {
      const mapEntry = dataMap.get(xValue) || { Xvalue: xValue };

      // Fill in missing series keys with 0
      data.forEach(item => {
        const seriesKey = item[keys[0]];
        if (!(seriesKey in mapEntry)) {
          mapEntry[seriesKey] = 0;
        }
      });

      return mapEntry;
    });

  } else {
    throw new Error('Invalid number of columns. Expected 2 or 3 columns.');
  }

  return transformedData;
}
export function transformComplexData2(inputData: ComplexModel[]): GroupedData[] {
  const groupedData: { [key: string]: { [key: string]: number } } = inputData.reduce(
    (acc, current) => {
      const { Serie, Xvalue, Yvalue } = current;

      const stringValue = Xvalue.toString();

      if (!acc[stringValue]) {
        acc[stringValue] = {};
      }

      if (!acc[stringValue][Serie]) {
        acc[stringValue][Serie] = 0;
      }

      acc[stringValue][Serie] += Yvalue; // Sum up Yvalue for the same Serie

      return acc;
    }, {}
  );

  const transformedData: GroupedData[] = Object.entries(groupedData).map(
    ([Xvalue, seriesData]) => {
      const outputEntry: GroupedData = { Xvalue: parseInt(Xvalue, 10) };
      Object.entries(seriesData).forEach(([Series, value]) => {
        const formattedValue = typeof value === 'number' ? value.toFixed(2) : value;
        outputEntry[Series] = parseFloat(formattedValue);
      });

      return outputEntry;
    }
  );

  return transformedData;
}
export function complexGroupByVarietyAndSum(inputData: ComplexModel[]): ComplexModel[] {
  // Group by Serie and sum Xvalue and Yvalue separately
  const groupedData: { [key: string]: { XvalueSum: number; YvalueSum: number } } = inputData.reduce(
    (acc, current) => {
      const { Serie, Xvalue, Yvalue } = current;

      if (!acc[Serie]) {
        acc[Serie] = { XvalueSum: 0, YvalueSum: 0 };
      }

      acc[Serie].XvalueSum += parseInt(Xvalue.toString(), 10);
      acc[Serie].YvalueSum += Yvalue;

      return acc;
    },
    {}
  );

  const transformedData: ComplexModel[] = Object.entries(groupedData)
    .map(([Serie, { XvalueSum, YvalueSum }]) => ({
      Serie,
      Xvalue: XvalueSum.toString(),
      Yvalue: YvalueSum,
    }))
    .sort((a, b) => b.Yvalue - a.Yvalue);

  return transformedData;
}
export function sortByX(groupedComponentData) {
  console.log(groupedComponentData, "groupedComponentData");
  const sortedData = groupedComponentData.sort((a, b) => {
    // Assuming the first column value is numeric, you can change the comparison logic as needed
    return a[Object.keys(a)[0]] - b[Object.keys(b)[0]];
  });
  console.log(sortedData, "Sorted X data");
}

export function getLastKey(item) {
  const keys = Object.keys(item);
  return keys[keys.length - 1];  // Return the last key
};
export function getFirstKey(item) {
  const keys = Object.keys(item);
  return keys[0];
};

export function groupTwoColumnData(componentData, sort = 'none') {
  if (!componentData) {
    return; // Alternatively, throw new Error("Input data is not provided");
  }
  const numColumns = Object.keys(componentData[0]).length;
  if (numColumns !== 2) {
    return null; // Alternatively, throw new Error("Number of columns is not 2");
  }

  const groupedData = componentData.reduce((result, item) => {
    const [key, value] = Object.entries(item)[0];
    const [key2, value2] = Object.entries(item)[1];
    const formattedValue2 = Number(value2);
    const existingItem = result.find(obj => obj[key] === value);
    if (existingItem) {
      existingItem[key2] = (Number(existingItem[key2]) + formattedValue2).toFixed(2);
    } else {
      result.push({ [key]: value, [key2]: formattedValue2.toFixed(2) });
    }
    return result;
  }, []);

  if (sort !== 'none') {
    const keyToSortBy = Object.keys(componentData[0])[1]; // Always sorts by the second key's value
    groupedData.sort((a, b) => sort === 'asc' ? Number(a[keyToSortBy]) - Number(b[keyToSortBy]) : Number(b[keyToSortBy]) - Number(a[keyToSortBy]));
  }

  return groupedData;
}
// export function groupTwoColumnData(componentData, sort = 'none') {
//   if (!componentData) {
//     return; // Alternatively, throw new Error("Input data is not provided");
//   }

//   const numColumns = Object.keys(componentData[0]).length;
//   console.log("num", numColumns);
//   if (numColumns !== 2) {
//     return null; // Alternatively, throw new Error("Number of columns is not 2");
//   }

//   const groupedData = componentData.reduce((result, item) => {
//     const [key, value] = Object.entries(item)[0];
//     const [key2, value2] = Object.entries(item)[1];
//     const formattedValue2 = Number(value2);
//     const existingItem = result.find(obj => obj[key] === value);
//     if (existingItem) {
//       existingItem[key2] = (Number(existingItem[key2]) + formattedValue2).toFixed(2);
//     } else {
//       result.push({ [key]: value, [key2]: formattedValue2.toFixed(2) });
//     }
//     return result;
//   }, []);

//   if (sort !== 'none') {
//     const keyToSortBy = Object.keys(componentData[0])[1]; // Always sorts by the second key's value
//     groupedData.sort((a, b) => sort === 'asc' ? Number(a[keyToSortBy]) - Number(b[keyToSortBy]) : Number(b[keyToSortBy]) - Number(a[keyToSortBy]));
//   }

//   return groupedData;
// }


export function groupThreeColumnData(componentData, sort = 'none') {
  if (!componentData) {
    return;
  }
  const numColumns = Object.keys(componentData[0]).length;
  if (numColumns !== 3)
    return null;//throw new Error("number of columns where not 3");

  const groupedData = componentData.reduce((result, item) => {
    const [key, value] = Object.entries(item)[0];
    const [key2, value2] = Object.entries(item)[1];
    const [key3, value3] = Object.entries(item)[2];

    const formattedValue3 = Number(value3);
    const existingItemIndex = result.findIndex(obj => obj[key] === value && obj[key2] === value2);
    if (existingItemIndex !== -1) {
      result[existingItemIndex][key3] = ((Number(result[existingItemIndex][key3]) || 0) + Number(formattedValue3)).toFixed(2);
    } else {
      result.push({
        [key]: value,
        [key2]: value2,
        [key3]: formattedValue3.toFixed(2)
      });
    }
    return result;
  }, []);

  if (sort !== 'none') {
    const keyToSortBy = Object.keys(componentData[0])[2]; // Always sorts by the third key's value
    groupedData.sort((a, b) => sort === 'asc' ? Number(a[keyToSortBy]) - Number(b[keyToSortBy]) : Number(b[keyToSortBy]) - Number(a[keyToSortBy]));
  }

  return groupedData;
}
export function generateColumnsFromData(data: any, resultFormat: ResultFormatting, sortable: boolean): TableColumn[] {
  if (!data || data.length === 0) return [];

  const firstEntry = data[0];
  const keys = Object.keys(firstEntry);

  const columns: TableColumn[] = [];
  // const formatType = resultFormat === ResultFormatting.Currency ? 'currency' : 'standard';
  for (const key of keys) {
    columns.push({
      label: key,
      accessor: key,
      sortable: sortable,
      formatValue: resultFormat,
    });
  }

  return columns;
}

export const FormatResultValue = (value: number, formatValue: number, compact: boolean = false) => {
  if (isNaN(value))
    return value;
  if (formatValue == undefined) {
    return value;
  }
  if (formatValue && formatValue === 1) {
    if (compact) {
      return new Intl.NumberFormat('nb-NO', {
        // notation: 'compact',
        // style: 'currency',
        // currency: 'NOK',
        // compactDisplay: 'short',
        maximumSignificantDigits: 3,
        minimumFractionDigits: 2,
      }).format(value);
    }
    else {
      return new Intl.NumberFormat('nb-NO', {
        // style: 'currency',
        // currency: 'NOK',
        // maximumSignificantDigits: 3,
        minimumFractionDigits: 2,
      }).format(value);
    }

  }
  else if (formatValue && formatValue === 2) {
    return new Intl.NumberFormat("nb-NO", {
      style: "percent",
      // maximumSignificantDigits: 3,
      minimumFractionDigits: 2,
    }).format(value);
  }
  else if (formatValue && formatValue === 3) { //TODO add in backend.
    return new Intl.NumberFormat('nb-NO', {
      maximumSignificantDigits: 4,
      maximumFractionDigits: 2
    }).format(value / 1000);
  }
  else {
    return new Intl.NumberFormat('nb-NO', {
      maximumFractionDigits: 0
    }).format(value);
  }


}

export function mergeConnectionResults(connectionResults: ConnectionResults[], myfilter: string[]) { //export function mergeConnectionResults(connectionResults:ConnectionResults[], stateContext:any){
  const mergedData: string[] = connectionResults
    .filter(result => {

      if (myfilter.length === 0) {
        return !result.isError;
      }
      return myfilter.includes(result.licenseNo) && !result.isError;
    })
    .reduce((merged: string[], result) => {
      const parsedData: string[] = JSON.parse(result.data);
      if (Array.isArray(parsedData)) {
        merged.push(...parsedData);
      } else {
        merged.push(parsedData);
      }
      return merged;
    }, []);
  return mergedData;
}
export function sortDataByLastColumn(data: any[]) {
  if (!Array.isArray(data) || data.length === 0) {
    console.error("Invalid data passed to sortDataByLastColumn:", data);
    return [];
  }
  return data.sort((item1, item2) => {
    const keys = Object.keys(item1);
    const lastColumnKey = keys[keys.length - 1];
    const value1 = parseFloat(item1[lastColumnKey]);
    const value2 = parseFloat(item2[lastColumnKey]);
    return value2 - value1;
  })
}
export function sortDataByFirstColumn(data: any[]) {
  return data.sort((item1, item2) => {
    const keys = Object.keys(item1);
    const firstColumnKey = keys[0];
    const value1 = parseFloat(item1[firstColumnKey]);
    const value2 = parseFloat(item2[firstColumnKey]);
    return value1 - value2;
  })
}

const generateHorizontalGradient = (id, offsetStart, color1, color2, opacity1, opacity2) => (
  <linearGradient id={`gradientHorizontal${id}`} key={`gradientHorizontal-${id}`} x1="0" y1="0" x2="0" y2="1">
    <stop offset={offsetStart} stopColor={color1} stopOpacity={opacity1} />
    <stop offset="95%" stopColor={color2} stopOpacity={opacity2} />
  </linearGradient>
);

const generateVerticalGradient = (id, offsetStart, color1, color2, opacity1, opacity2) => (
  <linearGradient id={`gradientVertical${id}`} key={`gradientVertical-${id}`} x1="0" y1="0" x2="1" y2="0">
    <stop offset={offsetStart} stopColor={color1} stopOpacity={opacity1} />
    <stop offset="95%" stopColor={color2} stopOpacity={opacity2} />
  </linearGradient>
);


const getDefaultGradient = (isVertical) => {
  return isVertical
    ? generateVerticalGradient('default', '5%', '#CCCCCC', '#999999', 0.3, 0.8)
    : generateHorizontalGradient('default', '5%', '#CCCCCC', '#999999', 0.8, 0.3);
};


export const getGradientByIndex = (index: number, isVertical: boolean) => {
  switch (index) {
    case 1:
      return isVertical
        ? generateVerticalGradient('1', '5%', '#0088FE', '#0056b3', 0.3, 0.8)
        : generateHorizontalGradient('1', '5%', '#0088FE', '#0056b3', 0.8, 0.3);
    case 2:
      return isVertical
        ? generateVerticalGradient('2', '5%', '#00C49F', '#00a775', 0.3, 0.8)
        : generateHorizontalGradient('2', '5%', '#00C49F', '#00a775', 0.8, 0.3);
    case 3:
      return isVertical
        ? generateVerticalGradient('3', '5%', '#FFBB28', '#bf951c', 0.3, 0.8)
        : generateHorizontalGradient('3', '5%', '#FFBB28', '#bf951c', 0.8, 0.3);
    case 4:
      return isVertical
        ? generateVerticalGradient('4', '5%', '#FF8042', '#b3592a', 0.3, 0.8)
        : generateHorizontalGradient('4', '5%', '#FF8042', '#b3592a', 0.8, 0.3);
    case 5:
      return isVertical
        ? generateVerticalGradient('5', '5%', '#AF19FF', '#8000b3', 0.3, 0.8)
        : generateHorizontalGradient('5', '5%', '#AF19FF', '#8000b3', 0.8, 0.3);
    default:
      return getDefaultGradient(isVertical);
  }
};

export function encryptWithSalt(inputString, salt) {
  const saltedString = inputString + salt;
  const encodedString = btoa(saltedString);
  return encodedString;
}

export function decryptWithSalt(encodedString, salt) {
  const decodedString = atob(encodedString);
  const inputString = decodedString.slice(0, -salt.length);
  return inputString;
}