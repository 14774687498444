import React, { useState, useEffect } from 'react';
// import { authorizeUser } from '../../services/api.tsx';
import ApiService from '../../services/apiService.tsx'
import { LoginProps, OverlayProps } from '../../models/Types.tsx';
import './loginpage.css';
import { useStateContext } from '../../context/ContextProvider.tsx';
import { CredentialsModel } from '../../models/Dtos.tsx';
import Welcomepage from '../welcomepage/Welcompage.tsx';
import { decryptWithSalt, encryptWithSalt } from '../../util/commonfunctions.tsx';
import CookieConsentModal from '../cookieconsent/CookieConsent.tsx';
import { PresetIcons } from '../../util/constants.tsx';

const Loginpage = ({ onLogin }) => {
  const stateContext = useStateContext();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [showInfo, setShowInfo] = useState(false);

  const salt = "k5s5sghh42";
  const consent = localStorage.getItem('cookieConsent');

  useEffect(() => {
    if (consent !== 'accept') return;
    const encryptedUserData = localStorage.getItem('userData');
    if (encryptedUserData) {
      const userData = JSON.parse(encryptedUserData);
      setUsername(decryptWithSalt(userData.username, salt));
      setPassword(decryptWithSalt(userData.password, salt));
      setRememberMe(userData.rememberMe);
    }
  }, [consent]);

  const toggleRememberMe = () => setRememberMe(!rememberMe);

  const toggleInfo = () => {
    if (error) setError('');
    setShowInfo(!showInfo);
  };

  const handleLogin = async () => {
    try {

      setLoading(true);
      const userCredentials: CredentialsModel = { username, password };
      const response = await ApiService.authorizeUser(userCredentials);

      if (response.status === 201) {
        const newOverlayContext: OverlayProps = {
          onClose: () => { },
          children: <Welcomepage />,
        };
        stateContext.setOverlayContext(newOverlayContext);
        stateContext.setShowOverlay((prevShowOverlay) => !prevShowOverlay);
      }

      if (response) {

        const loginProp: LoginProps = {
          status: true,
          username,
          password,
        };

        if (consent === "accept") {

          if (rememberMe) {
            const userData = {
              username: encryptWithSalt(username, salt),
              password: encryptWithSalt(password, salt),
              rememberMe,
            };
            localStorage.setItem('userData', JSON.stringify(userData));
          } else {
            localStorage.removeItem('userData');
          }
        }

        onLogin(loginProp);
      } else {
        onLogin(false, '', '');
      }
    } catch (error) {
      setError(error.response?.data?.message || error.message);
      onLogin(false, '', '');
    } finally {
      setLoading(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') handleLogin();
  };

  return (
    <>
      <CookieConsentModal />
      <div className="login-container">
        <h2>PCK Dashboard</h2>

        <div className="login-info-container">
          <h3>Logg inn</h3>
          <button type="button" className="info-btn" onClick={toggleInfo}>
            {PresetIcons.FaInfoCircle}
            {/* <span aria-label="info">ℹ️</span> */}
          </button>
        </div>

        {showInfo && !error && (
          <div className="info-text">
            Her benytter du samme innlogging som for min.pckasse.no. Du skaffer deg tilganger ved å gå til PCKasse
            installasjonene, åpne admin, og navigere deg gjennom Oppsett, Programinnstillinger, Rutiner. Her finner du:
            Tilgang min.pckasse.no. Trykk på Opprett, og gi din e-post addresse tilgang.
          </div>
        )}
        {error && <p className="error-message">{error}</p>}

        <div className="login_input-container">
          <label htmlFor="username">Brukernavn:</label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>

        <div className="login_input-container">
          <label htmlFor="password">Passord:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={handleKeyPress}
          />
        </div>

        <div className="login_remember-me-container">
          <input type="checkbox" id="remember-me" checked={rememberMe} onChange={toggleRememberMe} />
          <span>Husk meg</span>
        </div>

        <button className="submit-btn" onClick={handleLogin} disabled={loading}>
          <span className="button-text">{loading ? 'Laster...' : 'Login'}</span>
          {loading && <div className="loader"></div>}
        </button>
      </div>
    </>
  );
};

export default Loginpage;
